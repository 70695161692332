import { useCallback, useEffect, useRef, useState } from "react";
import ChatComponent from "../ChatComponent";
import { ActiveChannelInterface } from "./../../interfaces/activeChannelInterface";
import GroupMembersList from "../GroupMembersList";
import { Call, CometChat, TextMessage } from "@cometchat/chat-sdk-javascript";
import { FriendRequest } from "./../../utils/class/FriendRequest";
import {
  deleteFriendRequestOrigin,
  deleteIncomingFriendRequest,
  deleteCustomMessage,
} from "./friendRequests";
import { NotificationInterface } from "./../../interfaces/notificationInterface";
import UserProfile from "./../../components/UserProfile";
import ReportUserModal from "./../../components/ReportModal/ReportUserModal";
import LoggedInUserProfile from "./../../components/UserProfile/LoggedInUserProfile";
import Timeline from "./../../components/Timeline";
import Menu from 'anchor-ui/menu';
import LeftSidebarMenu from "../LeftSideBarMenu";
import SettingsPage from "../SettingsPage";
import { Functions, httpsCallable } from "firebase/functions";
import { UserSettingsInterface } from "../../interfaces/userSettingsInterface";
import { ConversationInterface } from "../../interfaces/conversationInterface";
import { isGroupConversation } from "../../utils/isGroupConversationCheck";
import WarnUserModal from "../WarnModal";
import BanUserModal from "../BanUserModal";
import BanList from "../BanList";
import { checkIfUserHasPermissions, checkIfUserIsAdmin } from "../../utils/checkIfUserIsAdmin";
import { checkIfUserIsBlocked } from "../../utils/checkIfUserIsBlocked";
import { User } from "firebase/auth";
import { getProfileFromMetadata } from "../../utils/updateMetadata";
import SubscriptionsPage from "../SubscriptionsPage";
import { premiumChannelIds } from "../../utils/premiumChannelIds";
import { checkUserHasSubscription } from "../../utils/checkSubscription";
import { InactivityKickDialog } from "./InactivityKickDialog";

const ConversationSDK = ({
  setNotifications,
  unreadCount,
  setUnreadCount,
  currentChat,
  setCurrentChat,
  activeChannels,
  onlineMembersCount,
  handleLeaveChannel,

  handleChangeConversation,
  handleGroupConversationClick,
  activeTabIndex,
  setActiveTabIndex,
  loggedInUser,
  firebaseUser,

  conversations,
  setConversations,
  initialConversation,
  setInitialConversation,
  refreshConversations,

  incomingFriendRequests,
  loadIncomingFriendRequests,
  outgoingFriendRequests,
  loadOutgoingFriendRequests,
  blockedUsers,
  handleBlockUser,

  friends,
  loadFriends,
  setFriends,
  handleRemoveFriend,
  handleAcceptFriendRequest,
  handleFriendRequest,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,
  handleMuteUser,
  handleKickUser,
  handleLogoutKickUser,
  handleMakeUserAdmin,

  members,
  setMembers,
  chatWithUser,
  setChatWithUser,
  channels,
  setAlert,

  messages,
  setMessages,
  userSettings,
  setUserSettings,

  showLoadMostRecentMsgBtn,
  setShowLoadMostRecentMsgBtn,
  functions,
  payFunctions,

  handleLogout,
  handleChangeChannel,
  fetchGroupMembers,

  handleRemoveConversation,

  setWarningMessage,
  user,

  usersTyping,
  setUsersTyping,

  ongoingCall,
  setOngoingCall,
  outgoingCall,
  setOutgoingCall,
  handleInitiateCall,
  handleTerminateCall,

  needSubscriptionWarning,
  setNeedSubscriptionWarning,
}: {
  setNotifications: React.Dispatch<React.SetStateAction<NotificationInterface[] | null>>
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } },
  setUnreadCount: React.Dispatch<React.SetStateAction<{ [id: string]: { nr: number, chat: ActiveChannelInterface } }>>,
  currentChat: ActiveChannelInterface,
  setCurrentChat: React.Dispatch<React.SetStateAction<ActiveChannelInterface>>,
  activeChannels: ActiveChannelInterface[],
  onlineMembersCount: { [key: string]: number } | null;
  handleLeaveChannel: (channel: ActiveChannelInterface) => void,

  handleChangeConversation: (conversation: CometChat.Conversation) => void,
  handleGroupConversationClick: (group: CometChat.Group) => Promise<void>,
  activeTabIndex: number,
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>,
  loggedInUser: CometChat.User | null,
  firebaseUser: User,

  conversations: ConversationInterface[],
  setConversations: React.Dispatch<React.SetStateAction<ConversationInterface[]>>,
  initialConversation: CometChat.Conversation | null,
  setInitialConversation: React.Dispatch<React.SetStateAction<CometChat.Conversation | null>>,
  refreshConversations: () => void,

  incomingFriendRequests: FriendRequest[],
  loadIncomingFriendRequests: () => void,
  outgoingFriendRequests: FriendRequest[],
  loadOutgoingFriendRequests: () => void,
  blockedUsers: CometChat.User[],
  handleBlockUser: (userId: string) => void,

  friends: CometChat.User[],
  loadFriends: () => void,
  setFriends: React.Dispatch<React.SetStateAction<CometChat.User[]>>,
  handleRemoveFriend: (friend: CometChat.User) => void,
  handleAcceptFriendRequest: (request: FriendRequest) => void,
  handleFriendRequest: (user: CometChat.User) => Promise<void>,
  hasSentFriendRequest: (user: CometChat.User) => boolean,
  hasReceivedFriendRequest: (user: CometChat.User) => boolean,
  handleAddOrRemoveFriend: (friend: CometChat.User) => void,
  handleMuteUser: (user: CometChat.User) => void,
  handleKickUser: (user: CometChat.User, group: CometChat.Group) => void,
  handleLogoutKickUser: (user: CometChat.User) => void,
  handleMakeUserAdmin: (user: CometChat.User) => void,

  members: CometChat.GroupMember[],
  setMembers: React.Dispatch<React.SetStateAction<CometChat.GroupMember[]>>,
  chatWithUser: CometChat.User | undefined,
  setChatWithUser: React.Dispatch<React.SetStateAction<CometChat.User | undefined>>,
  channels: CometChat.Group[],
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>

  messages: CometChat.BaseMessage[];
  setMessages: React.Dispatch<React.SetStateAction<CometChat.BaseMessage[]>>;
  userSettings: UserSettingsInterface;
  setUserSettings: React.Dispatch<React.SetStateAction<UserSettingsInterface>>;

  showLoadMostRecentMsgBtn: boolean;
  setShowLoadMostRecentMsgBtn: React.Dispatch<React.SetStateAction<boolean>>;
  functions: Functions;
  payFunctions: Functions;

  handleLogout: () => void;

  handleChangeChannel: (channel: ActiveChannelInterface) => void;
  fetchGroupMembers: (currentChat: ActiveChannelInterface) => void;

  handleRemoveConversation: (conversation: any) => void;

  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  user: User | null;

  usersTyping: { name: string; typingStarted: number; }[] | null;
  setUsersTyping: React.Dispatch<React.SetStateAction<{ name: string; typingStarted: number; }[] | null>>;

  ongoingCall: Call | null;
  setOngoingCall: React.Dispatch<React.SetStateAction<Call | null>>;
  outgoingCall: { call: Call, receiver: CometChat.User } | null;
  setOutgoingCall: React.Dispatch<React.SetStateAction<{ call: Call, receiver: CometChat.User } | null>>;
  handleInitiateCall: (user: CometChat.User) => void;
  handleTerminateCall: () => void;

  needSubscriptionWarning: React.ReactNode | null;
  setNeedSubscriptionWarning: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
}) => {
  const [friendToDelete, setFriendToDelete] = useState<CometChat.User | null>(null);
  const [userToReport, setUserToReport] = useState<{ user: CometChat.User, guid?: string } | null>(null);
  const [userToWarn, setUserToWarn] = useState<CometChat.User | null>(null);
  const [userToBan, setUserToBan] = useState<CometChat.User | null>(null);

  const [leftMenuOpen, setLeftMenuOpen] = useState(false);
  const [rightMenuOpen, setRightMenuOpen] = useState(false);

  const [messageToForward, setMessageToForward] = useState<CometChat.BaseMessage | null>(null);

  // const [messageInput, setMessageInput] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const [commandMenuOpen, setCommandMenuOpen] = useState(false);
  const [lastSentMessage, setLastSentMessage] = useState({ msg: '', sent: 0 });
  const [messageToReply, setMessageToReply] = useState<any>(null);

  const [isFetching, setIsFetching] = useState(true);
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false);

  const [startedTyping, setStartedTyping] = useState(false);
  const [typingNotification, setTypingNotification] = useState<CometChat.TypingIndicator>(currentChat.isGroup ?
    new CometChat.TypingIndicator(currentChat.id, CometChat.RECEIVER_TYPE.GROUP)
    : new CometChat.TypingIndicator(chatWithUser?.getUid(), CometChat.RECEIVER_TYPE.USER));

  const [openInactivityKickDialog, setOpenInactivityKickDialog] = useState(false);

  const initiateNewConversation = async ({ userId, user }: { userId?: string, user?: CometChat.User }) => {
    if (!user && userId) user = await CometChat.getUser(userId);

    if (user) {
      const lastMsg = new CometChat.TextMessage(
        user.getUid(),
        'Init',
        CometChat.RECEIVER_TYPE.USER
      );

      // Create blank conversation object
      const newConversation = new CometChat.Conversation(
        user.getUid(),
        CometChat.RECEIVER_TYPE.USER,
        lastMsg,
        user,
        0,
        [],
        0,
        []
      );

      const newChat = {
        id: user.getUid(),
        receiverId: user.getUid(),
        icon: user.getAvatar(),
        name: user.getName(),
        joinedAt: Date.now(),
        isGroup: false,
        conversation: newConversation,
      };

      setCurrentChat(newChat);
      setInitialConversation(newConversation);
      setActiveTabIndex(1);
    }
  };

  const handleDenyFriendRequest = async (request: FriendRequest) => {
    if (!loggedInUser) return;

    await deleteIncomingFriendRequest(functions, request, 'friend_request_denied');

    loadIncomingFriendRequests();
  };

  const handleCancelFriendRequest = async (request: FriendRequest) => {
    if (!loggedInUser) return;

    await deleteCustomMessage(functions, request.messageId);
    await deleteFriendRequestOrigin(functions, request.getUid());

    setAlert({ message: 'Vriendschapsverzoek geannuleerd', type: 'success' });

    loadOutgoingFriendRequests();
  };

  const startPrivateChat = async (user: CometChat.User) => {
    try {
      const receiverID = user.getUid();
      const receiverType = CometChat.RECEIVER_TYPE.USER;

      CometChat.getConversation(receiverID, receiverType).then(
        conversation => {
          handleChangeConversation(conversation);
        },
        error => {
          // If conversation doesn't exist, create a new one
          initiateNewConversation({ user: user });
        }
      );

    } catch (error) {
      console.error("Error starting private chat:", error);
      setAlert({ message: 'Er is iets misgegaan bij het starten van een privéchat, probeer het opnieuw', type: 'error' });
    }
  };

  const handleDeleteMessageAsAdmin = async (message: CometChat.BaseMessage) => {
    try {
      const fetchGroupMembers = httpsCallable(functions, 'deleteMessage');
      await fetchGroupMembers({ messageId: message.getId(), uid: message.getSender().getUid() });

      setMessages(prev => prev.filter(msg => msg.getId() !== message.getId()));
      setAlert({ message: 'Bericht verwijderd', type: 'success' });
    } catch (error) {
      console.error("Error deleting message:", error);
      setAlert({ message: 'Er is iets misgegaan bij het verwijderen van het bericht', type: 'error' });
    }
  };

  const showUserProfile = (user: CometChat.User) => {
    setCurrentChat((prev: any) => ({ ...prev, userProfile: user }));
  };

  const handleBanUser = async (user: CometChat.User) => {
    setUserToBan(user);
  };

  const buildMessagesRequest = (fetchFromTimestamp: number, limit: number, currentChat: any) => {
    const isUser = !currentChat.isGroup && currentChat.receiverId;
    const targetId = isUser ? currentChat.receiverId : currentChat.id;
    return new CometChat.MessagesRequestBuilder()
    [isUser ? 'setUID' : 'setGUID'](targetId + '')
      .setTimestamp(fetchFromTimestamp)
      .setLimit(limit)
      .build();
  };

  const filterValidMessages = (timestamp: number, messageList: CometChat.BaseMessage[], messages: CometChat.BaseMessage[], currentChat: any, checkDuplicate?: boolean) => {
    return messageList.filter(message => {
      if (checkDuplicate) {
        const isDuplicate = messages.some(msg => msg.getId() === message.getId());
        if (isDuplicate) return false;
      }

      const isBlocked = checkIfUserIsBlocked(blockedUsers, message.getSender().getUid());
      if (isBlocked) return false;

      const sentAfterJoined = message.getSentAt() > timestamp;
      const isTextMessage = message instanceof CometChat.TextMessage;
      const isMediaMessage = message instanceof CometChat.MediaMessage;
      const isActionMessage = message.getCategory() === 'action';
      const isGroupMemberMessage = message.getType() === 'groupMember' && (message as any).getMessage();
      const isPollMessage = message.getType() === 'extension_poll';
      const isCustomMessage = message instanceof CometChat.CustomMessage;
      const hasUsername = message.getSender().getName() !== "unknown" ||
        message.getSender().getName() !== "";

      if (currentChat.isOpen && isActionMessage) return false;

      if (isCustomMessage && !isPollMessage) {
        const tags = (message.getCustomData() as any)?.tags;
        return tags?.includes('reload_pinned') ?? false;
      }

      const hasValidContent = isTextMessage ? message.getText() : isMediaMessage ? message.getURL() : false;

      return (
        (
          (
            isTextMessage ||
            isMediaMessage ||
            isActionMessage
          ) &&
          hasValidContent
        ) ||
        isGroupMemberMessage ||
        isPollMessage) &&
        sentAfterJoined &&
        hasUsername;
    });
  };

  // Fetch the most recent 50 messages for the current chat
  const fetchMessages = useCallback(async () => {
    setIsFetching(true);
    setMessages([]);
    // joinedat 16:38
    const timestamp = currentChat.isOpen ? Math.floor(currentChat.joinedAt / 1000) : 0;
    let messagesRequest = buildMessagesRequest(0, 100, currentChat);

    try {
      // const request = currentChat.isOpen ? messagesRequest.fetchNext() : messagesRequest.fetchPrevious();
      // const messageList = await request;
      const messageList = await messagesRequest.fetchPrevious();
      const validMessages = filterValidMessages(timestamp, messageList, messages, currentChat);
      setShowLoadMoreBtn(messageList.length > 50 && validMessages.length >= 50);
      setMessages(validMessages);
      setShowLoadMostRecentMsgBtn(false);
      setIsFetching(false);
    } catch (error) {
      console.error('Message fetching failed with error:', error);
      setAlert({ message: 'Berichten konden niet worden opgehaald', type: 'error' });
    }
  }, [currentChat, setAlert]);

  const handleSendMessage = async (
    event: any,
    receiver: string,
    isGroup: boolean,
    messageText?: string,
    isForwarded?: boolean
  ) => {
    if (!loggedInUser) return;
    const metadata = getProfileFromMetadata(loggedInUser);
    const isMuted = metadata.muted;

    // const textInput = (messageText ?? messageInput);
    const textInput = messageText ?? inputRef.current?.value ?? '';
    inputRef.current!.value = "";

    // Do not send message if the command menu is open and the enter key is pressed
    if (commandMenuOpen && event.key === 'Enter') return;

    if (isGroup && currentChat.isOpen) {
      const groupId = currentChat.id;

      const isPremium = checkUserHasSubscription(loggedInUser.getRole());
      if (!isPremium && premiumChannelIds.includes(groupId)) {
        premiumChannelIds.includes(groupId) && setNeedSubscriptionWarning(
          <>Je hebt een abonnement nodig om berichten te versturen in dit kanaal.</>
        );
        return;
      }
    }

    // Cannot send the same message twice in a row within 3 seconds
    if (textInput === lastSentMessage.msg && (Date.now() - lastSentMessage.sent) < 3000) {
      inputRef.current!.value = "";
      return;
    }

    // If the message is forwarded or isNotLastForwarded is not defined, set the last sent message
    if (!isForwarded) setLastSentMessage({ msg: textInput, sent: Date.now() });

    // Don't send message if the message input is empty
    if (!textInput?.trim()) return;

    let textMessage: TextMessage;
    if (!currentChat.id) return;

    textMessage = new CometChat.TextMessage(
      receiver,
      textInput,
      isGroup ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER
    );

    if (messageToForward && isForwarded) {
      const forwardedMessage = {
        messageText: (messageToForward as any).text,
        sender: (messageToForward as any).sender.name,
        sentAt: (messageToForward as any).sentAt,
      }

      textMessage.setMetadata({
        forwardedMessage,
      });
      // Set metadata for reply messages
    } else if (messageToReply) {
      textMessage.setMetadata({
        replyTo: messageToReply
      });
    }

    if (isMuted) {
      textMessage.setSender(loggedInUser);
      textMessage.setSentAt(Math.floor(Date.now() / 1000));
      // Set the messages only client side if the user is muted
      setMessages(prevMessages => [...prevMessages, textMessage as CometChat.TextMessage]);
      inputRef.current!.value = "";
      return;
    }

    await CometChat.sendMessage(textMessage).then(
      message => {
        if (message instanceof CometChat.TextMessage) {
          if (!currentChat.isOpen) {
            // Set the last message in the conversation list
            const updatedConversations = conversations.map((conversation: ConversationInterface) => {
              if (isGroupConversation(conversation)) {
                if ((conversation.conversation as any).guid === currentChat.id) {
                  return {
                    ...conversation,
                    lastMsg: message.getText(),
                    isMyMsg: true,
                  };
                }
              } else {
                if ((conversation.conversation as CometChat.Conversation).getConversationId() === currentChat.id) {
                  return {
                    ...conversation,
                    lastMsg: message.getText(),
                    isMyMsg: true,
                  };
                }
              }

              return conversation;
            });

            // Move the updated conversation to the top of the list
            const updatedConversationIndex = updatedConversations.findIndex((conversation: ConversationInterface) => {
              if (isGroupConversation(conversation)) {
                return (conversation.conversation as any).guid === currentChat.id;
              }

              return (conversation.conversation as CometChat.Conversation).getConversationId() === currentChat.id;
            });

            if (updatedConversationIndex !== -1) {
              const [updatedConversation] = updatedConversations.splice(updatedConversationIndex, 1);
              updatedConversations.unshift(updatedConversation);
            }

            setConversations(updatedConversations);
          }

          // Update message list
          if (showLoadMostRecentMsgBtn) fetchMessages();
          else if (!isForwarded) setMessages(prevMessages => [...prevMessages, message]);
        }
        if (!isForwarded) {
          inputRef.current!.value = "";
          setMessageToReply(null);
        }
      },
      error => {
        const code = error.code;
        if (code === "ERR_TOO_MANY_REQUESTS") {
          setAlert({ message: 'Niet spammen! Probeer na een minuut nog eens.', type: 'error' });
          return;
        }

        console.error('Message sending failed with error:', error);
        setAlert({ message: 'Bericht kon niet worden verzonden', type: 'error' });
      }
    ).finally(() => {
      // End typing indicator if user sends a message
      if (startedTyping) CometChat.endTyping(currentChat.isGroup ?
        new CometChat.TypingIndicator(currentChat.id, CometChat.RECEIVER_TYPE.GROUP)
        : new CometChat.TypingIndicator(chatWithUser?.getUid(), CometChat.RECEIVER_TYPE.USER));
    });
  };

  const handleSendImage = (event: React.ChangeEvent<HTMLInputElement>, receiver: string, isGroup: boolean, isForwarded?: boolean) => {
    if (!loggedInUser) return;
    const metadata = getProfileFromMetadata(loggedInUser);
    const isMuted = metadata.muted;

    if (event.target.files == null || event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    const mediaMessage = new CometChat.MediaMessage(
      receiver,
      file,
      CometChat.MESSAGE_TYPE.IMAGE,
      isGroup ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER
    );

    // Set metadata for reply messages
    if (messageToReply) {
      mediaMessage.setMetadata({
        replyTo: messageToReply
      });
    }

    if (isMuted) {
      mediaMessage.setSender(loggedInUser);
      mediaMessage.setSentAt(Math.floor(Date.now() / 1000));
      // Set the messages only client side if the user is muted
      setMessages(prevMessages => [...prevMessages, mediaMessage as CometChat.MediaMessage]);
      return;
    }

    CometChat.sendMediaMessage(mediaMessage).then(
      message => {
        if (message instanceof CometChat.MediaMessage) {
          // Set last conversation message if chat is not open
          if (!currentChat.isOpen) {
            const updatedConversations = conversations.map((conversation: ConversationInterface) => {
              if (isGroupConversation(conversation)) {
                if ((conversation.conversation as any).guid === currentChat.id) {
                  conversation.lastMsg = 'Afbeelding';
                  conversation.isMyMsg = true;
                }
              } else {
                if ((conversation.conversation as CometChat.Conversation).getConversationId() === currentChat.id) {
                  conversation.lastMsg = 'Afbeelding';
                  conversation.isMyMsg = true;
                }
              }

              return conversation;
            });

            setConversations(updatedConversations);
          }

          // Update message list
          if (showLoadMostRecentMsgBtn) fetchMessages();
          else setMessages(prevMessages => [...prevMessages, message]);

          setMessageToReply(null);
        }
      },
      error => {
        console.error('Image sending failed with error:', error);
        setAlert({ message: 'Afbeelding kon niet worden verzonden', type: 'error' });
      }
    );
  };

  useEffect(() => {
    // If initialConversation is set, check if it exists in conversations, if it does, remove it
    if (initialConversation) {
      let existingConversation: ConversationInterface | null = null;
      existingConversation = (conversations as (ConversationInterface)[])
        .filter((conversation) => conversation.conversation instanceof CometChat.Conversation)
        .find((conversation: ConversationInterface) => {
          // If conversation is a group, return false
          if (isGroupConversation(conversation)) return;

          // If conversation exists, set it as the current chat if it's the same as the initial conversation
          const conversationWith = (conversation.conversation as CometChat.Conversation).getConversationWith();
          const initialConversationWith = initialConversation.getConversationWith();

          if ('getUid' in conversationWith && 'getUid' in initialConversationWith) {
            return conversationWith.getUid() === initialConversationWith.getUid();
          }
          return false;
        }) || null;

      if (existingConversation) {
        if (!isGroupConversation(existingConversation)) return;
        // If conversation exists, set it as the current chat if it's the same as the initial conversation
        const conversationWith = (existingConversation.conversation as CometChat.Conversation).getConversationWith();
        const initialConversationWith = initialConversation.getConversationWith();

        if ('getUid' in conversationWith && 'getUid' in initialConversationWith) {
          const existingConversationUid = (conversationWith as CometChat.User).getUid();
          const initialConversationUid = (initialConversationWith as CometChat.User).getUid();

          if (existingConversationUid === initialConversationUid) {
            handleChangeConversation(existingConversation.conversation as CometChat.Conversation);
          }
        }

        setInitialConversation(null);
      }
    }
  }, [conversations, initialConversation, currentChat]);

  const leftSidebarMenuProps = {
    activeChannels,
    onlineMembersCount,

    currentChat,
    setCurrentChat,
    handleLeaveChannel,

    conversations,
    setConversations,
    initialConversation,
    refreshConversations,
    handleGroupConversationClick,

    friends,
    loadFriends,
    setFriends,
    handleRemoveFriend,
    incomingFriendRequests,
    loadIncomingFriendRequests,
    outgoingFriendRequests,
    loadOutgoingFriendRequests,

    handleAcceptFriendRequest,
    handleDenyFriendRequest,
    handleCancelFriendRequest,

    handleConversationClick: handleChangeConversation,

    unreadCount,
    setUnreadCount,

    activeTabIndex,
    setActiveTabIndex,

    setNotifications,
    loggedInUser,

    channels,
    startPrivateChat,
    blockedUsers,
    handleBlockUser,

    userToReport,
    setUserToReport,
    members,
    setAlert,

    friendToDelete,
    setFriendToDelete,
    showUserProfile,

    isSideNav: false,

    handleLogout,

    handleChangeChannel,
    functions,

    userSettings,

    handleRemoveConversation,

    setWarningMessage,

    messageToForward,
    setMessageToForward,

    handleSendMessage,
    handleSendImage,

    handleInitiateCall,

    needSubscriptionWarning,
    setNeedSubscriptionWarning,
  };

  const groupMembersListProps = {
    currentChat,
    loggedInUser,
    friends,
    handleRemoveFriend,
    handleChangeConversation,

    incomingFriendRequests,
    handleAcceptFriendRequest,
    outgoingFriendRequests,
    loadOutgoingFriendRequests,
    initiateNewConversation,
    blockedUsers,
    handleBlockUser,
    handleFriendRequest,
    hasSentFriendRequest,
    hasReceivedFriendRequest,
    handleAddOrRemoveFriend,
    handleBanUser,
    handleMuteUser,
    handleKickUser,
    handleLogoutKickUser,
    handleMakeUserAdmin,
    setCurrentChat,
    startPrivateChat,
    members,
    setMembers,
    chatWithUser,
    setChatWithUser,
    setUserToReport,
    setUserToWarn,
    setAlert,
    isSideNav: false,
    functions,
    fetchGroupMembers,
    userSettings,
    refreshConversations,

    ongoingCall,
    setOngoingCall,
    outgoingCall,
    setOutgoingCall,

    handleInitiateCall,
    handleTerminateCall,
  };

  return (
    <>
      <ReportUserModal
        userToReport={userToReport}
        setUserToReport={setUserToReport}
        setAlert={setAlert}
      />

      <WarnUserModal
        userToWarn={userToWarn}
        setUserToWarn={setUserToWarn}
        setAlert={setAlert}
        functions={functions}
        handleKickUser={handleKickUser}
        handleLogoutKickUser={handleLogoutKickUser}
        currentChat={currentChat}
      />

      <BanUserModal
        userToBan={userToBan}
        setUserToBan={setUserToBan}
        setAlert={setAlert}
        functions={functions}
        loggedInUser={loggedInUser!}
      />

      <InactivityKickDialog
        isOpen={openInactivityKickDialog}
        setIsOpen={setOpenInactivityKickDialog}
        setCurrentChat={setCurrentChat}
      />

      <Menu
        closeMenu={() => setLeftMenuOpen(!leftMenuOpen)}
        open={leftMenuOpen}
        style={{ backgroundColor: 'transparent' }}
      >
        <LeftSidebarMenu {...leftSidebarMenuProps} isSideNav />
      </Menu>

      <Menu
        closeMenu={() => setRightMenuOpen(!rightMenuOpen)}
        open={rightMenuOpen}
        position="right"
        style={{ backgroundColor: 'transparent' }}
      >
        <GroupMembersList {...groupMembersListProps} isSideNav />
      </Menu>

      <LeftSidebarMenu {...leftSidebarMenuProps} />

      {
        currentChat.showSubscriptionPage ? (
          <SubscriptionsPage
            firebaseUser={firebaseUser}
            payFunctions={payFunctions}
            loggedInUser={loggedInUser!}
            functions={functions}
          />
        ) :
          (currentChat.userProfile && !currentChat.showBanList) ?
            <div className="user-profile-section">
              {currentChat.userProfile && currentChat.userProfile.getUid() === loggedInUser?.getUid() ?
                <>
                  {currentChat.showSettingPage ?
                    <SettingsPage
                      setCurrentChat={setCurrentChat}
                      loggedInUser={loggedInUser}
                      setAlert={setAlert}

                      userSettings={userSettings}
                      setUserSettings={setUserSettings}

                      blockedUsers={blockedUsers}
                      handleBlockUser={handleBlockUser}
                      functions={functions}
                      handleLogout={handleLogout}
                      user={user}

                      setNeedSubscriptionWarning={setNeedSubscriptionWarning}
                    /> :
                    <LoggedInUserProfile
                      user={currentChat.userProfile}
                      setCurrentChat={setCurrentChat}

                      channels={channels}

                      leftMenuOpen={leftMenuOpen}
                      setLeftMenuOpen={setLeftMenuOpen}
                      unreadCount={unreadCount}
                      setAlert={setAlert}
                      functions={functions}

                      setNeedSubscriptionWarning={setNeedSubscriptionWarning}
                    />
                  }
                </> :
                <UserProfile
                  user={currentChat.userProfile}
                  setCurrentChat={setCurrentChat}
                  startPrivateChat={startPrivateChat}

                  friends={friends}
                  hasSentFriendRequest={hasSentFriendRequest}
                  hasReceivedFriendRequest={hasReceivedFriendRequest}
                  handleAddOrRemoveFriend={handleAddOrRemoveFriend}

                  leftMenuOpen={leftMenuOpen}
                  setLeftMenuOpen={setLeftMenuOpen}
                  unreadCount={unreadCount}

                  handleBanUser={handleBanUser}
                  loggedInUserIsAdmin={checkIfUserHasPermissions(loggedInUser!)}
                  setUserToWarn={setUserToWarn}

                  handleLogoutKickUser={handleLogoutKickUser}
                  functions={functions}

                  handleMuteUser={handleMuteUser}
                  blockedUsers={blockedUsers}

                  handleInitiateCall={handleInitiateCall}

                  isFriend={friends.some(friend => friend.getUid() === currentChat?.userProfile?.getUid())}
                />
              }
            </div> :
            (currentChat.showBanList && checkIfUserIsAdmin(loggedInUser!)) ? (
              <BanList
                functions={functions}
                setAlert={setAlert}
                loggedInUser={loggedInUser!}
              />
            ) :
              currentChat.id ? (
                <>
                  <ChatComponent
                    currentChat={currentChat}
                    setCurrentChat={setCurrentChat}
                    conversations={conversations}
                    loggedInUser={loggedInUser}
                    loadIncomingFriendRequests={loadIncomingFriendRequests}
                    loadOutgoingFriendRequests={loadOutgoingFriendRequests}
                    setNotifications={setNotifications}
                    blockedUsers={blockedUsers}
                    handleBlockUser={handleBlockUser}

                    friends={friends}
                    hasSentFriendRequest={hasSentFriendRequest}
                    hasReceivedFriendRequest={hasReceivedFriendRequest}
                    handleAddOrRemoveFriend={handleAddOrRemoveFriend}

                    setUserToReport={setUserToReport}
                    setAlert={setAlert}

                    messages={messages}
                    setMessages={setMessages}

                    leftMenuOpen={leftMenuOpen}
                    setLeftMenuOpen={setLeftMenuOpen}
                    rightMenuOpen={rightMenuOpen}
                    setRightMenuOpen={setRightMenuOpen}

                    unreadCount={unreadCount}

                    groupMembers={members}
                    chatWithUser={chatWithUser}
                    showLoadMostRecentMsgBtn={showLoadMostRecentMsgBtn}
                    setShowLoadMostRecentMsgBtn={setShowLoadMostRecentMsgBtn}

                    handleMuteUser={handleMuteUser}
                    handleKickUser={handleKickUser}
                    handleLogoutKickUser={handleLogoutKickUser}
                    handleBanUser={handleBanUser}
                    handleDeleteMessageAsAdmin={handleDeleteMessageAsAdmin}

                    userSettings={userSettings}

                    handleRemoveConversation={handleRemoveConversation}
                    setUserToWarn={setUserToWarn}
                    setMessageToForward={setMessageToForward}

                    inputRef={inputRef}
                    commandMenuOpen={commandMenuOpen}
                    setCommandMenuOpen={setCommandMenuOpen}
                    messageToReply={messageToReply}
                    setMessageToReply={setMessageToReply}
                    handleSendMessage={handleSendMessage}
                    handleSendImage={handleSendImage}

                    isFetching={isFetching}
                    fetchMessages={fetchMessages}
                    buildMessagesRequest={buildMessagesRequest}

                    showLoadMoreBtn={showLoadMoreBtn}
                    setShowLoadMoreBtn={setShowLoadMoreBtn}

                    filterValidMessages={filterValidMessages}
                    setActiveTabIndex={setActiveTabIndex}

                    startedTyping={startedTyping}
                    setStartedTyping={setStartedTyping}
                    typingNotification={typingNotification}

                    usersTyping={usersTyping}
                    setUsersTyping={setUsersTyping}
                    setTypingNotification={setTypingNotification}

                    ongoingCall={ongoingCall}
                    setOngoingCall={setOngoingCall}
                    outgoingCall={outgoingCall}
                    setOutgoingCall={setOutgoingCall}

                    handleInitiateCall={handleInitiateCall}
                    handleTerminateCall={handleTerminateCall}

                    setOpenInactivityKickDialog={setOpenInactivityKickDialog}
                  />

                  <GroupMembersList {...groupMembersListProps} />
                </>
              ) : loggedInUser &&
              <Timeline
                loggedInUser={loggedInUser}
                functions={functions}
                friends={friends}
                showUserProfile={showUserProfile}
                startPrivateChat={startPrivateChat}
                setUserToReport={setUserToReport}
                setFriendToDelete={setFriendToDelete}
                blockedUsers={blockedUsers}
                handleBlockUser={handleBlockUser}

                conversations={conversations}
                unreadCount={unreadCount}
                handleConversationClick={handleChangeConversation}
                handleGroupConversationClick={handleGroupConversationClick}
                initialConversation={initialConversation}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                refreshConversations={refreshConversations}
                setUnreadCount={setUnreadCount}
                setAlert={setAlert}

                leftMenuOpen={leftMenuOpen}
                setLeftMenuOpen={setLeftMenuOpen}

                userSettings={userSettings}

                handleRemoveConversation={handleRemoveConversation}

                ongoingCall={ongoingCall}
                setOngoingCall={setOngoingCall}
                outgoingCall={outgoingCall}
                setOutgoingCall={setOutgoingCall}

                handleInitiateCall={handleInitiateCall}

                setNeedSubscriptionWarning={setNeedSubscriptionWarning}
              />
      }
    </>
  );
};

export default ConversationSDK;