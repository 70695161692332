import React, { useState } from 'react';
 import { Call, CometChat } from "@cometchat/chat-sdk-javascript";
 import ListItem from 'anchor-ui/list-item';
 import defaultAvatar from "./../../assets/default_avatar.jpg";
 import { IconMore } from 'anchor-ui/icons';
 import MenuItem from 'anchor-ui/menu-item';
 import {
  IconConversation,
  IconPerson,
  IconAddFriend,
  IconBlock,
  IconReport,
  IconThumbsDown,
  IconHammer,
  IconHand,
  IconExit,
  IconHeart,
  IconAddImage,
  IconMute,
 } from 'anchor-ui/icons';
 import Avatar from 'anchor-ui/avatar';
 import { getActiveColor } from './../../utils/activeColor';
 import IconMenu from 'anchor-ui/icon-menu';
 import Divider from 'anchor-ui/divider';

 import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
 import { getGenderFromMetadata } from './../../utils/getGenderFromMetadata';
 import { getFontSizeStyle } from '../../utils/getFontSizeStyle';

 import AdminBadge from "anchor-ui/admin-badge";
 import { getProfileFromMetadata } from "../../utils/updateMetadata";
 import { checkIfUserCanBeCalled } from "../../utils/CallFunctions";

 const GroupMemberListItem = ({
  key,
  member,
  isFriend,
  isBlocked,
  isPublicProfile,
  hasSentFriendRequest,
  hasReceivedFriendRequest,

  loggedInUser,
  currentChat,

  startPrivateChat,
  showUserProfile,
  setUserToReport,
  setUserToWarn,
  handleAddOrRemoveFriend,
  handleBlockUser,
  handleMuteUser,
  handleKickUser,
  handleLogoutKickUser,
  handleBanUser,

  loggedInUserIsAdmin,
  loggedInUserIsModerator,
  isAdmin,
  isModerator,
  generalFontSize,

  handleInitiateCall,
  handleTerminateCall,
  ongoingCall,
  outgoingCall,

  friends,
 }: {
  key: number,
  member: CometChat.User,
  isFriend: boolean,
  isBlocked: boolean,
  isPublicProfile: boolean,
  hasSentFriendRequest: boolean,
  hasReceivedFriendRequest: boolean,

  loggedInUser: CometChat.User | null,
  currentChat: ActiveChannelInterface,

  startPrivateChat: (user: CometChat.User) => void,
  showUserProfile: (user: CometChat.User) => void,
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void,
  setUserToWarn: (user: CometChat.User) => void,
  handleAddOrRemoveFriend: (friend: CometChat.User) => void,
  handleBlockUser: (userId: string) => void,
  handleMuteUser: (user: CometChat.User, unMute: boolean) => void,
  handleKickUser: (user: CometChat.User, group: CometChat.Group) => void,
  handleLogoutKickUser: (user: CometChat.User) => void,
  handleBanUser: (user: CometChat.User) => void,

  loggedInUserIsAdmin: boolean;
  loggedInUserIsModerator: boolean;
  isAdmin?: boolean,
  isModerator?: boolean,
  generalFontSize: string;

  handleInitiateCall: (user: CometChat.User) => void;
  handleTerminateCall: () => void;
  ongoingCall: Call | null;
  outgoingCall: { call: Call, receiver: CometChat.User } | null;

  friends?: CometChat.User[];
 }) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const profile = getProfileFromMetadata(member);
  const isMuted = profile?.muted || false;

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    setAnchorEl(null);
  };

  return (
    <ListItem
      key={key}
      primaryText={member.getName()}
      primaryTextStyle={getFontSizeStyle(generalFontSize)}
      avatar={
        <Avatar
          image={member.getAvatar() ? member.getAvatar() : defaultAvatar}
          status={isBlocked ? null : member.getStatus()}
        />
      }
      textBadge={
        <>
          {(isAdmin || isModerator) &&
            <AdminBadge text={isAdmin ? "Admin" : "Mod"} style={{ marginRight: "4px" }} />
          }
          {member.getRole() === "royal" ?
            <AdminBadge text="Royal" style={{ marginRight: "4px", fontWeight: "bold" }} /> :
            member.getRole() === "vip" ?
              <AdminBadge text="VIP" style={{ marginRight: "4px", fontWeight: "bold" }} /> :
              member.getRole() === "premium" &&
              <AdminBadge text="PREMIUM" style={{ marginRight: "4px", fontWeight: "bold" }} />
          }
          {isFriend &&
            <AdminBadge text="Vriend" />
          }
        </>
      }
      blocked={isBlocked}
      secondaryText={getGenderFromMetadata(member)}
      rightButton={
        !loggedInUser ? null :
          member.getUid() === loggedInUser.getUid() ? null : (
            <>
              <IconMenu
              icon={<IconMore />}
                open={isMenuOpen}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
              >
                {isPublicProfile || loggedInUserIsAdmin ?
                  <>
                    <MenuItem
                      text="Webcams 18+"
                      icon={<IconAddImage color={getActiveColor()} />}
                      onClick={() => window.open("https://www.pikantcams.nl/nl?pi=chatplaza_v2", '_blank')}
                    />
                    <MenuItem
                      text="Dating M/V"
                      icon={<IconHeart color={getActiveColor()} />}
                      onClick={() => window.open("https://ds1.nl/c/?si=51&li=1646487&wi=250877&ws=", '_blank')}
                    />
                    <Divider />

                    <MenuItem
                      text="Start privé kanaal"
                      icon={<IconConversation />}
                      onClick={() => startPrivateChat(member)}
                    />
                    {
                      checkIfUserCanBeCalled(member, isFriend) &&
                      <>
                        {ongoingCall || outgoingCall ?
                          <MenuItem
                            text="Stop audiogesprek"
                            icon={<IconPerson />}
                            onClick={handleTerminateCall}
                          /> :
                          <MenuItem
                            text={"Start audio of videogesprek"}
                            icon={<IconReport />}
                            onClick={() => handleInitiateCall(member)}
                          />
                        }
                      </>
                    }

                    {(profile &&
                      !profile.settings?.profileVisibility ||
                      profile?.settings?.profileVisibility === "public" ||
                      (profile?.settings?.profileVisibility === "friends" && (isFriend ||
                        friends?.some(friend => friend.getUid() === member.getUid()))) ||
                      profile && loggedInUserIsAdmin) && (
                        <MenuItem
                          text="Bekijk profiel"
                          icon={<IconPerson />}
                          onClick={() => showUserProfile(member)}
                        />
                      )}

                    {!hasSentFriendRequest &&
                      <MenuItem
                        text={isFriend ?
                          "Verwijder Vriend" :
                          hasReceivedFriendRequest ?
                            "Accepteer vriendschapsverzoek" :
                            "Stuur een vriendschapsverzoek"
                        }
                        icon={<IconAddFriend />}
                        onClick={() => handleAddOrRemoveFriend(member)}
                      />
                    }
                  </> :
                  <>
                    {isFriend || hasReceivedFriendRequest &&
                      <MenuItem
                        text={isFriend ?
                          "Verwijder Vriend" : "Accepteer vriendschapsverzoek"
                        }
                        icon={<IconAddFriend />}
                        onClick={() => handleAddOrRemoveFriend(member)}
                      />
                    }
                  </>
                }
                <MenuItem
                  text={isBlocked ?
                    "Deblokkeer gebruiker" :
                    "Blokkeer gebruiker"
                  }
                  icon={<IconBlock />}
                  onClick={() => handleBlockUser(member.getUid())}
                />

                <MenuItem
                  text="Rapporteer gebruiker"
                  icon={<IconReport />}
                  onClick={() => setUserToReport({ user: member, guid: currentChat.id })}
                />

                {
                  (!isAdmin && !isModerator && (loggedInUserIsAdmin || loggedInUserIsModerator)) && (
                    <>
                      <Divider />
                      <MenuItem
                        text="Stuur waarschuwing"
                        icon={<IconHand color={getActiveColor()} />}
                        onClick={() => setUserToWarn(member)}
                      />
                      <MenuItem
                        text={`${isMuted ? "Unm" : "M"}ute gebruiker`}
                        icon={<IconMute color={getActiveColor()} />}
                        onClick={() => handleMuteUser(member, isMuted)}
                      />
                      <MenuItem
                        text="Kick gebruiker"
                        icon={<IconThumbsDown color={getActiveColor()} />}
                        onClick={() => handleKickUser(member, new CometChat.Group(currentChat.id, currentChat.name))}
                      />
                      <MenuItem
                        text="Harde kick"
                        icon={<IconExit color={getActiveColor()} />}
                        onClick={() => handleLogoutKickUser(member)}
                      />
                      <MenuItem
                        text="Ban gebruiker"
                        icon={<IconHammer color={getActiveColor()} />}
                        onClick={() => handleBanUser(member)}
                      />
                    </>
                  )
                }
              </IconMenu>
            </>
          )
      }
    />
  );
}

export default GroupMemberListItem;