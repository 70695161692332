import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Hero from '../Layout/Hero';
import './styles.css';
import Dialog from "anchor-ui/dialog";
import Button from "anchor-ui/button";
import { User } from 'firebase/auth';
import { Functions, httpsCallable } from 'firebase/functions';

interface PaymentResult {
  redirectUrl: string;
  sessionId: string;
}

interface PriceDetails {
  price: number;
  monthlyPrice: number;
  discount: number;
  finalPrice: number;
}

interface SubscriptionType {
  id: string;
  tier: number;
  name: string;
  role: string;
  prices: {
    '1y': number;
    '2y': number;
  };
  description: string[];
}

const subscriptionTypes: SubscriptionType[] = [
  {
    id: 'premium',
    tier: 1,
    name: '⭐ Premium 🥉',
    role: 'premium',
    prices: {
      '1y': 2450,
      '2y': 4475
    },
    description: [
      "✔️ Andere gebruikers blokkeren",
      "✔️ Een opvallende 'Premium'-badge bij je naam",
      "✔️ Jouw account wordt bovenaan de online gebruikerslijst getoond",
      "✔️ Mogelijkheid om tot 2 privé groepen aan te maken",
      "✔️ Audio- en videogesprekken met iedereen, ook met niet-betalende gebruikers",
    ]
  },
  {
    id: 'vip',
    tier: 2,
    name: '🌟 VIP 🎖️',
    role: "vip",
    prices: {
      '1y': 3945,
      '2y': 7150
    },
    description: [
      "✔️ Een exclusieve 'VIP'-badge bij je naam",
      "✔️ Extra ruimte voor galerijfoto's op je profiel",
      "✔️ Toegang tot andere lettertypes",
      "✔️ Mogelijkheid om een persoonlijke profielbanner in te stellen",
      "✔️ Creëer tot 5 privé groepen",
      "➕ Inclusief alle voordelen van Premium",
    ]
  },
  {
    id: "royal",
    tier: 3,
    name: "👑 Royal 🥇",
    role: "royal",
    prices: {
      '1y': 4975,
      '2y': 8900
    },
    description: [
      "✔️ Een prestigieuze 'Royal'-badge bij je naam",
      "✔️ Pas je berichtenstijl aan zodat deze voor iedereen opvalt",
      "✔️ Creëer onbeperkt privé groepen",
      "✔️ Volledig advertentievrij platform!",
      "➕ Inclusief alle voordelen van VIP & Premium",
    ]
  }
];

const normalizeRoleName = (role: string | null | undefined): string => {
  return (role || "").trim().toLowerCase();
};

const ignorableRoles = ["default", "verified", "moderator", "admin", "superadmin"];

const SubscriptionsPage = ({
  firebaseUser,
  payFunctions,
  loggedInUser,
  functions,
}: {
  firebaseUser: User;
  payFunctions: Functions;
  loggedInUser: CometChat.User;
  functions: Functions;
}) => {
  const [selectedSubscription, setSelectedSubscription] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState<string>(normalizeRoleName(loggedInUser.getRole()));
  const [currentRoleExpirationDate, setCurrentRoleExpirationDate] = useState<number>(0);
  const [subscriptionPrices, setSubscriptionPrices] = useState<Record<string, Record<'1y' | '2y', PriceDetails>>>({});
  const [isLoadingRole, setIsLoadingRole] = useState(true);

  // Memoized subscription tier mapping for efficiency
  const subscriptionTierMap = useMemo(() => {
    return subscriptionTypes.reduce((acc, sub) => {
      acc[normalizeRoleName(sub.role)] = sub.tier;
      return acc;
    }, {} as Record<string, number>);
  }, []);

  const getSubscriptionTier = useCallback(
    (role: string | null | undefined): number => {
      if (!role) {
        return 0;
      }
      const normalizedRole = normalizeRoleName(role);
      return (
        subscriptionTierMap[normalizedRole] ||
        (normalizedRole.includes("premium") ? 1 : null) ||
        (normalizedRole.includes("vip") ? 2 : null) ||
        (normalizedRole.includes("royal") ? 3 : null) ||
        0
      );
    },
    [subscriptionTierMap]
  );

  const calculateUpgradeDiscount = useCallback(
    (
      currentRole: string,
      expirationDate: number,
      targetSubscriptionId: string,
      allSubscriptionTypes: SubscriptionType[]
    ): number => {
      if (!currentRole || ignorableRoles.includes(currentRole) || expirationDate <= Date.now()) {
        return 0;
      }

      const currentTier = getSubscriptionTier(currentRole);
      if (currentTier === 0) {
        return 0;
      }

      const targetSub = allSubscriptionTypes.find(sub => sub.id === targetSubscriptionId);
      if (!targetSub || currentTier >= targetSub.tier) {
        return 0;
      }

      const currentSub = allSubscriptionTypes.find(sub => sub.tier === currentTier);
      if (!currentSub) {
        return 0;
      }

      const remainingTime = Math.max(0, expirationDate - Date.now());
      const remainingMonths = remainingTime / (1000 * 60 * 60 * 24 * 30.44);
      const pricePerMonth = currentSub.prices['2y'] / 24;

      const discount = Math.min(
        Math.ceil(remainingMonths * pricePerMonth),
        Math.ceil(18 * pricePerMonth) // Max discount for 18 months
      );

      return Math.min(discount, targetSub.prices['2y']);
    },
    [getSubscriptionTier]
  );

  const getUserRoleDataAsync = useCallback(async () => {
    setIsLoadingRole(true);
    try {
      const getUserRoleData = httpsCallable(functions, "getUserRoleData");
      const result = await getUserRoleData();
      if (result.data) {
        const { highestTierSubscription, expirationDate } = result.data as { highestTierSubscription: string; expirationDate: number };
        const role = highestTierSubscription || "verified";
        setCurrentUserRole(normalizeRoleName(role));
        setCurrentRoleExpirationDate(expirationDate);
      } else {
        console.error(">>> No data received from server");
        setCurrentUserRole(normalizeRoleName(loggedInUser.getRole())); // Fallback if API fails
        setCurrentRoleExpirationDate(0);
      }
    } catch (error) {
      console.error('Error fetching user role data:', error);
      setCurrentUserRole(normalizeRoleName(loggedInUser.getRole())); // Fallback on error
      setCurrentRoleExpirationDate(0);
    } finally {
      setIsLoadingRole(false);
    }
  }, [functions, loggedInUser]);

  useEffect(() => {
    if (!ignorableRoles.includes(currentUserRole)) {
      getUserRoleDataAsync();
    } else {
      setIsLoadingRole(false);
    }
  }, [currentUserRole, getUserRoleDataAsync]);

  // Memoize price calculation to avoid unnecessary re-calculations
  const calculatePriceDetails = useCallback(
    (subscriptionId: string, duration: '1y' | '2y', currentRole: string, expirationDate: number): PriceDetails => {
      const subscription = subscriptionTypes.find(sub => sub.id === subscriptionId);
      if (!subscription) return { price: 0, monthlyPrice: 0, discount: 0, finalPrice: 0 };

      const price = subscription.prices[duration];
      let discount = 0;

      if (currentRole && !ignorableRoles.includes(currentRole) && expirationDate > Date.now()) {
        discount = calculateUpgradeDiscount(
          currentRole,
          expirationDate,
          subscriptionId,
          subscriptionTypes
        );
      }

      const finalPrice = Math.max(0, price - discount);
      const monthlyPrice = finalPrice / (duration === '1y' ? 12 : 24);

      return {
        price,
        monthlyPrice,
        discount,
        finalPrice
      };
    },
    [calculateUpgradeDiscount, getSubscriptionTier]
  );

  useEffect(() => {
    if (!isLoadingRole) {
      const newPrices: Record<string, Record<'1y' | '2y', PriceDetails>> = {};
      subscriptionTypes.forEach(sub => {
        newPrices[sub.id] = {
          '1y': calculatePriceDetails(sub.id, '1y', currentUserRole, currentRoleExpirationDate),
          '2y': calculatePriceDetails(sub.id, '2y', currentUserRole, currentRoleExpirationDate),
        };
      });
      setSubscriptionPrices(newPrices);
    }
  }, [isLoadingRole, currentUserRole, currentRoleExpirationDate, calculatePriceDetails]);

  const getPriceDetails = useCallback(
    (subscriptionId: string, duration: '1y' | '2y'): PriceDetails => {
      return subscriptionPrices[subscriptionId]?.[duration] || { price: 0, monthlyPrice: 0, discount: 0, finalPrice: 0 };
    },
    [subscriptionPrices]
  );

  const handleSubscriptionSelect = useCallback((subscriptionId: string) => {
    setSelectedSubscription(subscriptionId);
    setDialogOpen(true);
  }, []);

  const getDialogHeader = useCallback(() => {
    const selected = subscriptionTypes.find(sub => sub.id === selectedSubscription);
    return selected ? selected.name : "Abonnement";
  }, [selectedSubscription]);

  const getDiscountMessage = useCallback(
    (currentRole: string, discount: number): string | null => {
      if (discount <= 0) return null;

      const roleNames: Record<string, string> = {
        premium: "Premium",
        vip: "VIP",
        royal: "Royal"
      };

      const normalizedCurrentRole = normalizeRoleName(currentRole);
      let displayRoleName = "Premium";
      if (normalizedCurrentRole.includes("vip")) {
        displayRoleName = "VIP";
      } else if (normalizedCurrentRole.includes("royal")) {
        displayRoleName = "Royal";
      }

      return `Je ontvangt €${(discount / 100).toFixed(2)} korting op dit abonnement omdat je al een actief ${displayRoleName} abonnement hebt.`;
    },
    []
  );

  const handlePayment = useCallback(async (subscriptionToPay: string) => {
    if (!selectedSubscription) {
      alert('Selecteer een abonnement');
      return;
    }

    try {
      const fUid = firebaseUser.uid;
      const createPayment = httpsCallable(payFunctions, 'createPaymentStripe');
      const result = await createPayment({
        subscription: subscriptionToPay,
        returnUrl: `${window.location.origin}/payment-result`,
        fUid,
      });

      if (result.data) {
        const paymentResult = result.data as PaymentResult;
        if (paymentResult.sessionId) {
          const cacheData = {
            sessionId: paymentResult.sessionId,
            timestamp: Date.now(),
          };
          localStorage.setItem(`paymentSession_${firebaseUser.uid.toLowerCase()}`, JSON.stringify(cacheData));
        }
        if (paymentResult.redirectUrl) {
          window.location.href = paymentResult.redirectUrl;
        } else {
          throw new Error('No redirect URL received');
        }
      } else {
        throw new Error('Invalid response from payment initiation');
      }
    } catch (error) {
      console.error('Payment initiation failed:', error);
      alert('Er is een fout opgetreden bij het starten van de betaling.');
    }
  }, [firebaseUser, payFunctions, selectedSubscription]);


  return (
    <>
      <Dialog
        open={dialogOpen}
        header={getDialogHeader()}
        hideDialog={() => setDialogOpen(false)}
        headerStyle={{ color: 'rgb(117, 117, 117)', fontSize: '24px', fontWeight: 'bolder', textAlign: 'center' }}
        style={{ backgroundColor: 'white' }}
        iconColor={'rgba(117, 117, 117, 0.5)'}
      >
        <span className="warning-dialog-text">
          Selecteer een abonnement & <br />upgrade nu je account! 🚀
          <br /><br />
          <small>Kies voor 1 of 2 jaar met een eenmalige betaling.</small>
          <br /><br />
          <Button
            onClick={() => { handlePayment(`${selectedSubscription}-1y`) }}
            inverted
          >
            {selectedSubscription && (
              <>
                {currentUserRole && !ignorableRoles.includes(currentUserRole) && currentRoleExpirationDate > Date.now() && getPriceDetails(selectedSubscription, '1y').discount > 0 ? (
                  <>
                    <span style={{ textDecoration: 'line-through', color: 'grey' }}>
                      €{(getPriceDetails(selectedSubscription, '1y').price / 100).toFixed(2)}
                    </span>
                    <br />
                    1 jaar: €{(getPriceDetails(selectedSubscription, '1y').finalPrice / 100).toFixed(2)}
                    <br />
                    <b>€{(getPriceDetails(selectedSubscription, '1y').monthlyPrice / 100).toFixed(2)} p/m</b>
                  </>
                ) : (
                  <>
                    1 jaar: €{(getPriceDetails(selectedSubscription, '1y').price / 100).toFixed(2)}
                    <br />
                    <b>€{(getPriceDetails(selectedSubscription, '1y').monthlyPrice / 100).toFixed(2)} p/m</b>
                  </>
                )}
              </>
            )}
          </Button>
          &nbsp;&nbsp;
          <Button
            onClick={() => { handlePayment(`${selectedSubscription}-2y`) }}
          >
            {selectedSubscription && (
              <>
                {currentUserRole && !ignorableRoles.includes(currentUserRole) && currentRoleExpirationDate > Date.now() && getPriceDetails(selectedSubscription, '2y').discount > 0 ? (
                  <>
                    <span style={{ textDecoration: 'line-through', color: 'grey' }}>
                      €{(getPriceDetails(selectedSubscription, '2y').price / 100).toFixed(2)}
                    </span>
                    <br />
                    2 jaar: €{(getPriceDetails(selectedSubscription, '2y').finalPrice / 100).toFixed(2)}
                    <br />
                    <b>€{(getPriceDetails(selectedSubscription, '2y').monthlyPrice / 100).toFixed(2)} p/m</b>
                  </>
                ) : (
                  <>
                    2 jaar: €{(getPriceDetails(selectedSubscription, '2y').price / 100).toFixed(2)}
                    <br />
                    <b>€{(getPriceDetails(selectedSubscription, '2y').monthlyPrice / 100).toFixed(2)} p/m</b>
                  </>
                )}
              </>
            )}
          </Button>
        </span>
      </Dialog>

      <div className="w-100">
        <Hero
          heroText="Abonnementen"
          heroImg={"/vip-bg.avif"}
          className="chat-hero"
        />

        <div className="subscriptions-container">
          {isLoadingRole ? (
            <p>Laden van abonnementinformatie...</p>
          ) : (
            <div className="subscriptions-grid">
              {subscriptionTypes.map((subscription, index) => {
                const price1y = getPriceDetails(subscription.id, '1y');
                const price2y = getPriceDetails(subscription.id, '2y');

                return (
                  <div key={subscription.id} className="subscription-card">
                    <div className="subscription-header">
                      <h2>{subscription.name} {index === 1 && (<><br /> (meest gekozen)</>)}</h2>
                    </div>
                    <div className="subscription-content">
                      <ul className="subscription-features">
                        {subscription.description.map((feature, idx) => (
                          <li key={idx}>{feature}</li>
                        ))}
                      </ul>
                      {(() => {
                        const discountMessage = getDiscountMessage(
                          currentUserRole,
                          getPriceDetails(subscription.id, '2y').discount
                        );
                        return discountMessage && (
                          <>
                            <i>
                              {discountMessage}
                            </i>
                            <br /><br /><br />
                          </>
                        );
                      })()}
                      <div className="subscription-pricing">
                        <div className="price-options">
                          <div className="price-option">
                            <span>1 jaar</span>
                            {currentUserRole && !ignorableRoles.includes(currentUserRole) && currentRoleExpirationDate > Date.now() && price1y.discount > 0 ? (
                              <>
                                <span style={{ textDecoration: 'line-through', color: 'grey' }}>
                                  €{(price1y.price / 100 / 12).toFixed(2)}
                                </span>
                                <br />
                                <strong>€{(price1y.finalPrice / 100 / 12).toFixed(2)} p/m</strong>
                              </>
                            ) : (
                              <strong>€{((price1y.price / 100) / 12).toFixed(2)} p/m</strong>
                            )}
                          </div>
                          <div className="price-option">
                            <span>2 jaar</span>
                            {currentUserRole && !ignorableRoles.includes(currentUserRole) && currentRoleExpirationDate > Date.now() && price2y.discount > 0 ? (
                              <>
                                <span style={{ textDecoration: 'line-through', color: 'grey' }}>
                                  €{((price2y.price / 100 / 24)).toFixed(2)}
                                </span>
                                <br />
                                <strong>€{(price2y.finalPrice / 100 / 24).toFixed(2)} p/m</strong>
                              </>
                            ) : (
                              <strong>€{((price2y.price / 100) / 24).toFixed(2)} p/m</strong>
                            )}
                          </div>
                        </div>
                        <button
                          className="subscription-button"
                          onClick={() => handleSubscriptionSelect(subscription.id)}
                        >
                          Selecteer {subscription.name}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SubscriptionsPage;