import ongewenstSvg from "../assets/channel-icons/ongewenst.svg";
import ongewenstActiveSvg from "../assets/channel-icons/ongewenst_active.svg";
import vijftigPlusSvg from "../assets/channel-icons/50p.svg";
import vijftigPlusActiveSvg from "../assets/channel-icons/50p_active.svg";
import adminHulpSvg from "../assets/channel-icons/adminhulp.svg";
import adminHulpActiveSvg from "../assets/channel-icons/adminhulp_active.svg";
import allNightCafeSvg from "../assets/channel-icons/allnightcafe.svg";
import allNightCafeActiveSvg from "../assets/channel-icons/allnightcafe_active.svg";
import babesSvg from "../assets/channel-icons/babes.svg";
import babesActiveSvg from "../assets/channel-icons/babes_active.svg";
import biboxSvg from "../assets/channel-icons/bibox.svg";
import biboxActiveSvg from "../assets/channel-icons/bibox_active.svg";
import bruinCafeSvg from "../assets/channel-icons/bruincafe.svg";
import bruinCafeActiveSvg from "../assets/channel-icons/bruincafe_active.svg";
import chatXlSvg from "../assets/channel-icons/chatxl.svg";
import chatXLActiveSvg from "../assets/channel-icons/chatxl_active.svg";
import chatPointSvg from "../assets/channel-icons/chatpoint.svg";
import chatPointActiveSvg from "../assets/channel-icons/chatpoint_active.svg";
import clubSvg from "../assets/channel-icons/club.svg";
import clubActiveSvg from "../assets/channel-icons/club_active.svg";
import cyberPartySvg from "../assets/channel-icons/cyberparty.svg";
import cyberPartyActiveSvg from "../assets/channel-icons/cyberparty_active.svg";
import deHuiskamerSvg from "../assets/channel-icons/huiskamer.svg";
import deHuiskamerActiveSvg from "../assets/channel-icons/huiskamer_active.svg";
import familyMeetingSvg from "../assets/channel-icons/familymeeting.svg";
import familyMeetingActiveSvg from "../assets/channel-icons/familymeeting_active.svg";
import gaylifeSvg from "../assets/channel-icons/gaylife.svg";
import gaylifeActiveSvg from "../assets/channel-icons/gaylife_active.svg";
import gewaagdSvg from "../assets/channel-icons/gewaagd.svg";
import gewaagdActiveSvg from "../assets/channel-icons/gewaagd_active.svg";
import jongerenchatSvg from "../assets/channel-icons/jongerenchat.svg";
import jongerenchatActiveSvg from "../assets/channel-icons/jongerenchat_active.svg";
import lesboxSvg from "../assets/channel-icons/lesbox.svg";
import lesboxActiveSvg from "../assets/channel-icons/lesbox_active.svg";
import oldiesSvg from "../assets/channel-icons/oldies.svg";
import oldiesActiveSvg from "../assets/channel-icons/oldies_active.svg";
import parenclubSvg from "../assets/channel-icons/parenclub.svg";
import parenclubActiveSvg from "../assets/channel-icons/parenclub_active.svg";
import pikantSvg from "../assets/channel-icons/pikant.svg";
import pikantActiveSvg from "../assets/channel-icons/pikant_active.svg";
import receptenSvg from "../assets/channel-icons/recepten.svg";
import receptenActiveSvg from "../assets/channel-icons/recepten_active.svg";
import saunaSvg from "../assets/channel-icons/sauna.svg";
import saunaActiveSvg from "../assets/channel-icons/sauna_active.svg";
import smDungeonSvg from "../assets/channel-icons/smdungeon.svg";
import smDungeonActiveSvg from "../assets/channel-icons/smdungeon_active.svg";
import spiritueelSvg from "../assets/channel-icons/spiritueel.svg";
import spiritueelActiveSvg from "../assets/channel-icons/spiritueel_active.svg";
import sportSvg from "../assets/channel-icons/sport.svg";
import sportActiveSvg from "../assets/channel-icons/sport_active.svg";
import truthOrDareSvg from "../assets/channel-icons/truthordare.svg";
import truthOrDareActiveSvg from "../assets/channel-icons/truthordare_active.svg";
import vrijgezellenSvg from "../assets/channel-icons/vrijgezellen.svg";
import vrijgezellenActiveSvg from "../assets/channel-icons/vrijgezellen_active.svg";
import defaultImage from "./../assets/default_channel_image.png";
import defaultSelectedImage from "./../assets/default_channel_image_selected.png";
import roleplaySvg from "../assets/channel-icons/roleplay.svg";
import roleplayActiveSvg from "../assets/channel-icons/roleplay_active.svg";

export const getAvatar = (channelName: string, isActive?: boolean) => {
  switch (channelName) {
    case "50+":
      return isActive ? vijftigPlusActiveSvg : vijftigPlusSvg;
    case "Admin Hulp":
      return isActive ? adminHulpActiveSvg : adminHulpSvg;
    case "AllNightCafé":
      return isActive ? allNightCafeActiveSvg : allNightCafeSvg;
    case "Babes":
      return isActive ? babesActiveSvg : babesSvg;
    case "Bi-Box":
      return isActive ? biboxActiveSvg : biboxSvg;
    case "Bruin Café":
      return isActive ? bruinCafeActiveSvg : bruinCafeSvg;
    case "Chat XL":
      return isActive ? chatXLActiveSvg : chatXlSvg;
    case "Chatpoint":
      return isActive ? chatPointActiveSvg : chatPointSvg;
    case "Club":
      return isActive ? clubActiveSvg : clubSvg;
    case "CyberParty":
      return isActive ? cyberPartyActiveSvg : cyberPartySvg;
    case "De Huiskamer":
      return isActive ? deHuiskamerActiveSvg : deHuiskamerSvg;
    case "Family Meeting":
      return isActive ? familyMeetingActiveSvg : familyMeetingSvg;
    case "Gaylife":
      return isActive ? gaylifeActiveSvg : gaylifeSvg;
    case "Gewaagd":
      return isActive ? gewaagdActiveSvg : gewaagdSvg;
    case "Jongeren Chat":
      return isActive ? jongerenchatActiveSvg : jongerenchatSvg;
    case "Lesbox":
      return isActive ? lesboxActiveSvg : lesboxSvg;
    case "Oldies":
      return isActive ? oldiesActiveSvg : oldiesSvg;
    case "Ongewenst":
      return isActive ? ongewenstActiveSvg : ongewenstSvg;
    case "Parenclub":
      return isActive ? parenclubActiveSvg : parenclubSvg;
    case "Pikant":
      return isActive ? pikantActiveSvg : pikantSvg;
    case "Recepten":
      return isActive ? receptenActiveSvg : receptenSvg;
    case "Roleplay":
      return isActive ? roleplayActiveSvg : roleplaySvg;
    case "Sauna":
      return isActive ? saunaActiveSvg : saunaSvg;
    case "SM Dungeon":
      return isActive ? smDungeonActiveSvg : smDungeonSvg;
    case "Spiritueel":
      return isActive ? spiritueelActiveSvg : spiritueelSvg;
    case "Sport":
      return isActive ? sportActiveSvg : sportSvg;
    case "Truth or Dare":
      return isActive ? truthOrDareActiveSvg : truthOrDareSvg;
    case "Vrijgezellen":
      return isActive ? vrijgezellenActiveSvg : vrijgezellenSvg;
    default:
      return isActive ? defaultSelectedImage : defaultImage;
  }
};