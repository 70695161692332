import IconMenu from 'anchor-ui/icon-menu';
import MenuItem from 'anchor-ui/menu-item';
import {
  IconClose,
  IconMore,
  IconPerson,
  IconConversation,
  IconReport,
  IconThumbsUp,
  IconRemoveFriend,
  IconBlock
} from 'anchor-ui/icons';
import { checkIfUserIsBlocked } from '../../../utils/checkIfUserIsBlocked';
import { FriendRequest } from '../../../utils/class/FriendRequest';
import { checkIfUserCanBeCalled } from '../../../utils/CallFunctions';
import { getProfileFromMetadata } from '../../../utils/updateMetadata';
import { checkIfUserIsAdmin } from '../../../utils/checkIfUserIsAdmin';

const FriendButtons = ({
  friend,
  showUserProfile,
  startPrivateChat,
  setUserToReport,
  setFriendToDelete,
  loggedInUser,

  blockedUsers,
  handleBlockUser,
  handleAcceptFriendRequest,
  handleDenyFriendRequest,
  handleCancelFriendRequest,
  isIncomingRequest,
  isOutgoingRequest,
}: {
  friend: CometChat.User | FriendRequest;
  showUserProfile: (user: CometChat.User) => void;
  startPrivateChat: (user: CometChat.User) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setFriendToDelete: (friend: CometChat.User | null) => void;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  loggedInUser: CometChat.User | null;

  handleAcceptFriendRequest?: (friend: FriendRequest) => void;
  handleDenyFriendRequest?: (friend: FriendRequest) => void;
  handleCancelFriendRequest?: (friend: FriendRequest) => void;
  isIncomingRequest?: boolean;
  isOutgoingRequest?: boolean;
  loggedInUserIsAdmin?: boolean;
}) => {
  const userProfile = getProfileFromMetadata(friend as CometChat.User);
  const loggedInUserIsAdmin = checkIfUserIsAdmin(loggedInUser!);

  return (
    <IconMenu icon={<IconMore />}>

      {(userProfile &&
        !userProfile.settings?.profileVisibility ||
        userProfile?.settings?.profileVisibility === "public" ||
        (userProfile?.settings?.profileVisibility === "friends" ||
          userProfile && loggedInUserIsAdmin)) && (
          <MenuItem
            text="Bekijk Profiel"
            icon={<IconPerson />}
            onClick={() => showUserProfile(friend)}
          />
        )}
      <MenuItem
        text="Start Privé Kanaal"
        icon={<IconConversation />}
        onClick={() => startPrivateChat(friend)}
      />
      <MenuItem
        text="Rapporteer Gebruiker"
        icon={<IconReport />}
        onClick={() => setUserToReport({ user: friend })}
      />
      {(isIncomingRequest && handleAcceptFriendRequest) ? (
        <MenuItem
          text="Verzoek Accepteren"
          icon={<IconThumbsUp />}
          onClick={() => handleAcceptFriendRequest(friend as FriendRequest)}
        />
      ) : <></>}
      {(isIncomingRequest && handleDenyFriendRequest) ? (
        <MenuItem
          text="Verzoek Afwijzen"
          icon={<IconClose />}
          onClick={() => handleDenyFriendRequest(friend as FriendRequest)}
        />
      ) : <></>}
      {(isOutgoingRequest && handleCancelFriendRequest) ? (
        <MenuItem
          text="Verzoek Annuleren"
          icon={<IconClose />}
          onClick={() => handleCancelFriendRequest(friend as FriendRequest)}
        />
      ) : <></>}
      {(!isOutgoingRequest && !isIncomingRequest) ? (
        <MenuItem
          text={"Verwijder vriend"}
          icon={<IconRemoveFriend />}
          onClick={() => setFriendToDelete(friend)}
        />
      ) : <></>}
      <MenuItem
        text={checkIfUserIsBlocked(blockedUsers, friend.getUid()) ? "Deblokkeer gebruiker" : "Blokkeer gebruiker"}
        icon={<IconBlock />}
        onClick={() => handleBlockUser(friend.getUid())}
      />

      {
        checkIfUserCanBeCalled(friend, true) ? (
          <MenuItem
            text="Start audio of videogesprek"
            icon={<IconReport />}
            onClick={() => { }}
          />
        ) : <></>
      }

    </IconMenu>
  );
};

export default FriendButtons;
